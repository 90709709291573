<template>
  <div id="autocost-header-container" class="w-full flex flex-row justify-center">
    <div class="w-full max-w-[1300px] px-5 flex flex-row justify-between">
      <div class="ac-header-text" style="flex: 1">
        <div class="w-[350px]">
          <icon icon="svg:autocost" class="!w-16 !h-16 mb-3" />
          <p class="w-fit text-4xl bg-level-yellow text-black px-3 py-1 font-header">
            Instant pricing with
          </p>
          <p class="w-fit text-5xl font-bold bg-level-yellow text-black px-3 py-1 mb-2 font-header">
            AutoCost
          </p>
          <p class="text-black text-2xl mb-1">
            No more guessing on materials or labor costs. Starting at
          </p>
          <p class="font-bold text-3xl text-black">$75 per month</p>
          <div class="mt-5">
            <Btn
              class="mr-4"
              size="lg"
              severity="primary-black"
              :primary="false"
              @click="scrollToPricing"
            >
              {{ moduleButtonText }}
            </Btn>
            <Btn
              severity="tertiary"
              size="lg"
              :invert="true"
              @click="
                openInNewTab(
                  'https://calendly.com/autocost/zoom-meeting-with-costcertified-and-autocost'
                )
              "
            >
              Contact sales
            </Btn>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="autocost-content-container">
    <container size="7xl">
      <div id="autocost-features-container" class="ac-section mb-12">
        <div id="autocost-features-hero">
          <p class="ac-section-title">Features and benefits</p>
          <p class="ac-features-hero-subtitle ac-text-light">
            AutoCost optimizes your quote estimate workflow with an item catalog that uses
            market-related costs, specific to your location or zip code.
          </p>
        </div>
        <div class="ac-benefits-container">
          <Card>
            <icon class="!w-12 !h-12 mb-5" icon="svg:hourglassHalf" />
            <p class="ac-benefits-block-title">Save time during the estimation process</p>
            <p class="ac-benefits-block-detail ac-text-light">
              130,000+ construction materials, with pricing, readily available whenever you need to
              draft a proposal, so you no longer have to source individual costs for items or
              assemblies.
            </p>
          </Card>
          <Card>
            <icon class="!w-12 !h-12 mb-5" icon="svg:refresh" />
            <p class="ac-benefits-block-title">Update pricing at the tap of a button</p>
            <p class="ac-benefits-block-detail ac-text-light">
              AutoCost items added to a Bolster estimate will receive a notice when prices change,
              and can be updated immediately with the tap of a button.
            </p>
          </Card>
          <Card>
            <icon class="!w-12 !h-12 mb-5" icon="svg:flag" />
            <p class="ac-benefits-block-title">Locally priced, specific to your zip code</p>
            <p class="ac-benefits-block-detail ac-text-light">
              Items have local pricing that can be drilled down to a particular county, and for all
              3,033 counties across the United States.
            </p>
          </Card>
          <Card>
            <icon class="!w-12 !h-12 mb-5" icon="svg:material" />
            <p class="ac-benefits-block-title">Accurate pricing for fewer surprises</p>
            <p class="ac-benefits-block-detail ac-text-light">
              Prices are market-related, sourced directly from 1,000+ suppliers and maintained by a
              network of 300+ estimators.
            </p>
          </Card>
        </div>
      </div>
      <Card class="mb-12">
        <p class="text-[40px] font-header font-bold text-black">How it works</p>
        <img
          class="ac-hiw-image"
          src="@/assets/conveyor-belt.png"
          alt="Graphical representation of Bolster ingesting data and outputting a single AutoCost package"
        />
        <div id="autocost-hiw-steps">
          <div class="autocost-hiw-step">
            <div class="ac-hiw-step-number">1</div>
            <div class="ac-hiw-step-detail ac-text-light">
              Pricing data for 130,000+ construction materials are sourced directly from 4,322
              supplier branches across the US.
            </div>
          </div>
          <div class="autocost-hiw-step">
            <div class="ac-hiw-step-number">2</div>
            <div class="ac-hiw-step-detail ac-text-light">
              Bolster connects to this data via an API which you can access using our AutoCost
              feature
            </div>
          </div>
          <div class="autocost-hiw-step">
            <div class="ac-hiw-step-number">3</div>
            <div class="ac-hiw-step-detail ac-text-light">
              AutoCost filters this data so you access a catalog of items with pricing localized to
              your zip code or location
            </div>
          </div>
        </div>
      </Card>
      <Card class="mb-12">
        <p class="text-[40px] font-bold font-header text-center text-pitch-black mb-10">Pricing</p>
        <div class="flex flex-row justify-center flex-wrap">
          <div class="flex flex-col justify-center p-4">
            <div class="flex flex-row items-center mb-4 text-pitch-black">
              <p class="text-2xl font-bold">Yearly payment</p>
              <div class="p-1 ml-2 bg-pitch-black text-[10px] text-level-yellow">SAVE 24%</div>
            </div>

            <Card>
              <div class="h-96 flex flex-col justify-between">
                <div>
                  <p class="text-2xl font-medium text-pitch-black">$75 / month</p>
                  <p class="text-base text-start font-medium mb-8">$900 yearly</p>
                  <ul class="mb-8">
                    <li class="flex flex-row items-center">
                      <icon icon="check" class="mr-2" />
                      <p>Best value</p>
                    </li>
                    <li class="flex flex-row items-center">
                      <icon icon="check" class="mr-2" />
                      <p>Save 24%</p>
                    </li>
                    <li class="flex flex-row items-center">
                      <icon icon="check" class="mr-2" />
                      <p>Pay yearly</p>
                    </li>
                    <li class="flex flex-row items-center">
                      <icon icon="check" class="mr-2" />
                      <p>Tap-to-update pricing</p>
                    </li>
                    <li class="flex flex-row items-center">
                      <icon icon="check" class="mr-2" />
                      <p>Local to your zip code</p>
                    </li>
                    <li class="flex flex-row items-center">
                      <icon icon="check" class="mr-2" />
                      <p>Get 45-days free</p>
                    </li>
                  </ul>
                </div>
                <div v-if="!loading" class="w-full flex flex-row justify-center">
                  <div v-if="!isModuleEnabled && userCanEnableModule">
                    <Btn severity="primary-black" size="lg" @click="openTermsModal('year')">
                      {{ moduleButtonText }}
                    </Btn>
                  </div>
                  <div
                    v-else-if="
                      isModuleEnabled &&
                      isUserLoggedIn &&
                      isCompanyScoped &&
                      subscriptionTerm === 'Yearly'
                    "
                    class="ac-pricing-active-subscription"
                  >
                    <div class="ac-subscription-status">
                      <p class="ac-activation-status">
                        Activated{{ inFreeTrial ? ' (Trial)' : '' }}
                      </p>
                      <p class="ac-activation-term ac-text-light">
                        Subscription Term: {{ subscriptionTerm }}
                      </p>
                      <p v-if="inFreeTrial" class="ac-activation-trial-time ac-text-light">
                        Trial Ends: {{ freeTrialEndDateFormatted }}
                      </p>
                    </div>
                    <Btn severity="tertiary" @click="cancelProduct" v-if="userCanEnableModule"
                      >Cancel</Btn
                    >
                  </div>
                  <div
                    v-else-if="
                      isModuleEnabled &&
                      isUserLoggedIn &&
                      isCompanyScoped &&
                      subscriptionTerm === 'Monthly'
                    "
                    class="ac-pricing-active-subscription"
                  >
                    <Btn severity="tertiary" size="lg" @click="switchSubscriptionPayment('year')">
                      Switch to yearly
                    </Btn>
                  </div>
                  <div v-else>
                    <Btn
                      @click="
                        openInNewTab(
                          'https://calendly.com/autocost/zoom-meeting-with-costcertified-and-autocost'
                        )
                      "
                    >
                      Contact sales
                    </Btn>
                  </div>
                </div>
                <div v-else class="w-full h-1" />
              </div>
            </Card>
          </div>
          <div class="flex flex-col justify-center p-4">
            <p class="text-2xl font-bold text-center mb-4 text-pitch-black">Monthly payment</p>
            <Card>
              <div class="h-96 flex flex-col justify-between">
                <div>
                  <p class="text-2xl font-medium text-pitch-black">$99 / month</p>
                  <p class="text-base text-start font-medium mb-8">$1188 yearly</p>
                  <ul class="mb-8">
                    <li class="flex flex-row items-center">
                      <icon icon="check" class="mr-2" />
                      <p>Pay monthly</p>
                    </li>
                    <li class="flex flex-row items-center">
                      <icon icon="check" class="mr-2" />
                      <p>Tap-to-update pricing</p>
                    </li>
                    <li class="flex flex-row items-center">
                      <icon icon="check" class="mr-2" />
                      <p>Local to your zip code</p>
                    </li>
                    <li class="flex flex-row items-center">
                      <icon icon="check" class="mr-2" />
                      <p>Get 45-days free</p>
                    </li>
                  </ul>
                </div>
                <div v-if="!loading" class="w-full flex flex-row justify-center">
                  <div v-if="!isModuleEnabled && userCanEnableModule">
                    <Btn severity="primary-black" size="lg" @click="openTermsModal('month')">
                      {{ moduleButtonText }}
                    </Btn>
                  </div>
                  <div
                    v-else-if="
                      isModuleEnabled &&
                      isUserLoggedIn &&
                      isCompanyScoped &&
                      subscriptionTerm === 'Monthly'
                    "
                    class="ac-pricing-active-subscription"
                  >
                    <div class="ac-subscription-status">
                      <p class="ac-activation-status">
                        Activated{{ inFreeTrial ? ' (Trial)' : '' }}
                      </p>
                      <p class="ac-activation-term ac-text-light">
                        Subscription Term: {{ subscriptionTerm }}
                      </p>
                      <p v-if="inFreeTrial" class="ac-activation-trial-time ac-text-light">
                        Trial Ends: {{ freeTrialEndDateFormatted }}
                      </p>
                    </div>
                    <Btn v-if="userCanEnableModule" @click="cancelProduct" severity="tertiary"
                      >Cancel</Btn
                    >
                  </div>
                  <div
                    v-else-if="
                      isModuleEnabled &&
                      isUserLoggedIn &&
                      isCompanyScoped &&
                      subscriptionTerm === 'Yearly'
                    "
                    class="ac-pricing-active-subscription"
                  >
                    <Btn severity="tertiary" @click="switchSubscriptionPayment('month')">
                      Switch to monthly
                    </Btn>
                  </div>
                  <div v-else>
                    <Btn
                      @click="
                        openInNewTab(
                          'https://calendly.com/autocost/zoom-meeting-with-costcertified-and-autocost'
                        )
                      "
                    >
                      Contact sales
                    </Btn>
                  </div>
                </div>
                <div v-else class="w-full h-1" />
              </div>
            </Card>
          </div>
        </div>
      </Card>
      <Card class="mb-12">
        <p class="text-[40px] font-bold font-header text-center text-pitch-black mb-10">FAQ</p>
        <AccordionBolster :tabs="faqItems" />
      </Card>
    </container>
  </div>
  <mini-modal ref="termsModal">
    <div class="ac-terms-checkboxes mb-10">
      <div class="product-card-terms-checkbox">
        <checkbox
          v-model="acceptedTermsOfService"
          :boolean="true"
          :selected-value="true"
          :deselected-value="false"
          class="inline info"
        />
        <span class="ml-3">I agree and accept</span>
        <span
          class="product-card-terms-link"
          @click="openInNewTab('https://costcertified.com/legal/terms-and-conditions')"
          >Terms of Service</span
        >
      </div>
      <div class="product-card-terms-checkbox">
        <checkbox
          v-model="acceptedPrivacyPolicy"
          :boolean="true"
          :selected-value="true"
          :deselected-value="false"
          class="inline info"
        />
        <span class="ml-3">I agree and accept</span>
        <span
          class="product-card-terms-link"
          @click="openInNewTab('https://costcertified.com/legal/privacy-policy')"
          >Privacy Policy</span
        >
      </div>
    </div>
    <div class="w-full flex flex-row justify-between">
      <Btn severity="tertiary" class="w-40 mx-2" @click="closeTermsModal">Back</Btn>
      <Btn severity="primary-black" class="w-40 mx-2" @click="activateProduct"
        >Accept & Continue</Btn
      >
    </div>
  </mini-modal>
</template>

<script>
import AccordionBolster from '@/components/ui/AccordionBolster.vue'

export default {
  components: {
    AccordionBolster
  },
  data() {
    const faqItems = [
      {
        header: 'Can I change the details of an AutoCost item?',
        text: 'Yes. AutoCost items are just like any other Bolster item. Like a regular item, you are able to change all details of an item and save it to your catalog.'
      },
      {
        header: 'What type of data do you provide?',
        text: 'We provide access to 130,000+ construction material data points across 4,322 locations in the United States and Canada.'
      },
      {
        header: 'How often is AutoCost updated?',
        text: 'Data is continually collected and updated for our customers on a monthly basis.'
      },
      {
        header: 'Do you have local data for my region?',
        text: "Yes, if you're located in the United States or Canada."
      },
      {
        header: 'Can I buy AutoCost items from suppliers at the prices provided?',
        text: "Yes, pricing data is local to your company or project's zip or postal code."
      },
      {
        header: 'How accurate are AutoCost prices?',
        text: "Pricing data is sourced directly from suppliers and updated on a monthly basis, it's the most accurate construction cost dataset available today."
      }
    ]
    const selectedPricing = 'year'
    const isModuleEnabled = false
    const freeTrialStartUnix = null
    const freeTrialEndUnix = null
    const freeTrialStarted = false
    const activationLoading = 0
    const isAcceptingTerms = false
    const acceptedTermsOfService = false
    const acceptedPrivacyPolicy = false
    const loading = false

    return {
      faqItems,
      selectedPricing,
      isModuleEnabled,
      freeTrialStartUnix,
      freeTrialEndUnix,
      freeTrialStarted,
      activationLoading,
      isAcceptingTerms,
      acceptedTermsOfService,
      acceptedPrivacyPolicy,
      loading
    }
  },
  computed: {
    inFreeTrial() {
      if (!this.freeTrialStarted) return false
      const now = new Date()
      const currentUnix = now.getTime()
      return this.freeTrialEndUnix > currentUnix
    },
    freeTrialEndDateFormatted() {
      if (!this.freeTrialEndUnix) return ''
      const endDate = new Date(this.freeTrialEndUnix)
      const options = { day: '2-digit', year: 'numeric', month: 'short' }
      return `${endDate.toLocaleDateString('en-US', options)}`
    },
    isSuperUser() {
      return this.$store.state.session.user && this.$store.state.session.user.user_is_super_user
    },
    subscriptionTerm() {
      return this.selectedPricing === 'year' ? 'Yearly' : 'Monthly'
    },
    isUserLoggedIn() {
      return this.$store.state.session.isLoggedIn
    },
    isCompanyScoped() {
      return this.$store.state.session.scope.company
    },
    isCompanyUS() {
      const session = this.$store.state.session
      return session.company && session.company.country_id === 2
    },
    isCompanyCA() {
      const session = this.$store.state.session
      return session.company && session.company.country_id === 1
    },
    isUserCompanyAdmin() {
      const session = this.$store.state.session
      return session.user && session.user.user_is_admin === 1
    },
    moduleButtonText() {
      if (this.isModuleEnabled) {
        return 'Activated'
      }

      if (this.freeTrialStarted) {
        return this.inFreeTrial ? 'Resume trial' : 'Reactivate'
      }

      return 'Start free trial'
    },
    companiesAvailable() {
      return this.$store.state.session.scopableObjects.company
    },
    userCanEnableModule() {
      // Conditions for enabling module:
      return (
        this.isUserLoggedIn &&
        this.isCompanyScoped &&
        (this.isCompanyUS || this.isCompanyCA) &&
        (this.isUserCompanyAdmin || this.isSuperUser)
      )
    }
  },
  methods: {
    openTermsModal(type) {
      this.selectedPricing = type
      this.$refs.termsModal.open()
    },
    closeTermsModal() {
      this.$refs.termsModal.close()
    },
    openInNewTab(url) {
      window.open(url)
    },
    scrollToPricing() {
      c.scrollTo(this.$refs.acPricing)
    },
    goToCompany(route) {
      this.$router.push(`/${route}/autocost`)
    },
    async getSubscriptionStatus() {
      try {
        const { payload } = await this.$store.dispatch('ajax', {
          path: 'subscription/getProductSubscriptionStatus',
          data: {
            module_id: 24,
            company_id: this.$store.state.session.company.company_id
          }
        })
        this.isModuleEnabled = payload.is_module_enabled
        if (this.isModuleEnabled) {
          this.selectedPricing = payload.payment_schedule
        }
        this.freeTrialStartUnix = payload.free_trail_start_date

        const time = new Date().getTime()

        if (payload.free_trial_end_date) {
          this.freeTrialEndUnix = payload.free_trial_end_date * 1000
          this.freeTrialStarted = true
        } else {
          this.freeTrialEndUnix = time + 45 * 86400 * 1000
        }
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.message || 'Please refresh.',
          error: true
        })
      }
    },
    async cancelProduct() {
      if (this.activationLoading) return
      this.activationLoading = 1
      try {
        const payload = await this.cancelProductSubscription(24, 'AutoCost', true)
        this.isModuleEnabled = payload.is_module_enabled
        this.freeTrialStartUnix = payload.free_trail_start_date
        await this.cancelProductSubscription(30, 'AutoCost', false)
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.message || 'Please refresh.',
          error: true
        })
      }
      this.activationLoading = 0
    },
    async cancelProductSubscription(moduleId, moduleName, log) {
      const { payload } = await this.$store.dispatch('ajax', {
        path: 'subscription/cancelProductSubscription',
        data: {
          module_id: moduleId,
          module_name: moduleName,
          company_id: this.$store.state.session.company.company_id,
          log
        }
      })
      return payload
    },
    async activateProduct() {
      if (this.activationLoading || !this.acceptedTermsOfService || !this.acceptedPrivacyPolicy)
        return
      this.activationLoading = 1
      try {
        const payload = await this.activateProductSubscription(24, 'AutoCost', true)
        this.isModuleEnabled = payload.is_module_enabled
        this.freeTrialStartUnix = payload.free_trail_start_date
        this.freeTrialStarted = true
        await this.activateProductSubscription(30, 'AutoCost', false)
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.message || 'Please refresh.',
          error: true
        })
      }
      this.activationLoading = 0
      this.isAcceptingTerms = false
      this.$refs.termsModal.close()
    },
    async activateProductSubscription(moduleId, moduleName, log) {
      const { payload } = await this.$store.dispatch('ajax', {
        path: 'subscription/activateProductSubscription',
        data: {
          module_id: moduleId,
          module_name: moduleName,
          company_id: this.$store.state.session.company.company_id,
          free_trial_end_date: Math.floor(this.freeTrialEndUnix / 1000),
          payment_schedule: this.selectedPricing,
          log
        }
      })
      return payload
    },

    async switchSubscriptionPayment(paymentDuration) {
      if (this.selectedPricing === paymentDuration) return
      this.loading = true
      await this.cancelProduct()
      this.selectedPricing = paymentDuration
      await this.activateProduct()
      this.loading = false
    }
  },
  watch: {
    isCompanyScoped(val) {
      if (val) this.getSubscriptionStatus()
    }
  },
  async mounted() {
    this.loading = 1
    if (this.isUserLoggedIn && this.isCompanyScoped) {
      await this.getSubscriptionStatus()
    }
    this.loading = 0
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
#autocost-product-page {
  background-color: #fff;
}

#autocost-content-container {
  padding: 0 24px;

  @media (min-width: 768px) {
    padding: 0 100px;
  }
}

#autocost-header-container {
  height: 650px;
  max-height: 700px;
  background-image: url('@/assets/paper-bg-2.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: $flame-white;

  @media (min-width: 576px) {
    height: fit-content;
    padding: 50px 100px;
  }

  @media (min-width: 2000px) {
    background-image: url('@/assets/paper-bg.png');
  }
  @media (max-width: 1300px) {
    background-image: url('@/assets/paper-bg.png');
  }
}

#autocost-features-hero {
  margin-bottom: 96px;
}

#autocost-features-container {
  width: 100%;
}

#autocost-hiw-container {
  padding: 32px;
  text-align: center;

  @media (min-width: 992px) {
    padding: 105px;
  }
}

#autocost-hiw-steps {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

#autocost-pricing {
  max-width: 610px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding: 32px;

  @media (min-width: 576px) {
    padding: 96px 105px;
  }
}

#autocost-faq {
  padding: 32px;

  @media (min-width: 992px) {
    padding: 96px 210px;
  }
}

.ac-section {
  margin-top: 96px;
}

.ac-bg-darker {
  background-color: #fbfcfd;
}

.ac-header-text {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
}

.ac-new-feature-highlight {
  color: #cff2df;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 26.4px */
  text-transform: uppercase;
}

.ac-header-title {
  color: #fff;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 70.4px */
  margin: 24px 0;
}

.ac-header-subtitle {
  color: #cff2df;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 33.6px */
}

.ac-header-button {
  margin-top: 32px;
}

@media (min-width: 576px) {
  .ac-header-button:not(:last-of-type) {
    margin-right: 10px;
  }

  .ac-header-button:not(:first-of-type) {
    margin-left: 10px;
  }
}

.ac-header-gotcha {
  display: flex;
  color: #cff2df;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 12px */
  letter-spacing: -0.2px;
}

.ac-header-gotcha > img {
  margin-right: 12px;
}

.ac-hiw-step-number {
  width: 64px;
  height: 64px;
  display: block;
  background-color: #1c1a1b;
  border-radius: 99999px;
  color: #fbff00;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 64px;
  vertical-align: middle;
  margin-bottom: 24px;
}

.ac-hiw-step-detail {
  text-align: left;
}

.ac-hiw-image {
  margin-bottom: 56px;
  width: 100%;
}

.ac-section-title {
  color: #2c2b2b;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 52.8px */
  margin-bottom: 18px;
}

.ac-benefits-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  @media (min-width: 576px) {
    grid-template-columns: 1fr 1fr;
  }
}

.ac-benefits-block {
  padding: 32px;

  @media (min-width: 992px) {
    padding: 64px 105px;
  }
}

.ac-benefits-block-title {
  color: #2c2b2b;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 28.8px */
}

.ac-benefits-block-detail {
  margin-top: 24px;
}

.ac-benefits-block-icon {
  height: 64px;
  aspect-ratio: 1/1;
  margin-bottom: 24px;
}

.ac-features-hero-subtitle {
  max-width: 540px;
  text-align: center;
  margin: 0 auto;
}

.ac-pricing-info-well {
  border-radius: 10px;
  text-align: center;
  margin-bottom: 24px;
  padding: 32px;

  @media (min-width: 768px) {
    padding: 35px 57px;
  }
}

.ac-info-well-title {
  margin-bottom: 12px;
  font-weight: 600;
}

.ac-info-well-list {
  margin-top: 24px;
}

.ac-info-well-list li:before {
  content: '*';
  margin-right: 10px;
}

.ac-pricing-display {
  margin: 56px auto;
}

.ac-pricing-primary {
  color: #2c2b2b;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 28.8px */
}

.ac-pricing-secondary {
  min-height: 24px;
}

.ac-terms-actions {
  margin-top: 24px;
  text-align: center;
}

.ac-activation-status {
  color: #10be5e;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 28.8px */
  margin-bottom: 12px;
}

.ac-subscription-status {
  margin-bottom: 24px;
}

.ac-bordered {
  border: 2px solid #f6f6f6;
}

.ac-mb-56 {
  margin-bottom: 56px;
}

.ac-rounded-large {
  border-radius: 20px;
}

.ac-text-light {
  color: #959595;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 144%; /* 23.04px */
}

#ac-companies-list-title {
  margin-bottom: 12px;
}

.ac-available-company {
  color: #10be5e;
  font-size: 18px;
  line-height: 144%;
  cursor: pointer;
}

.ac-available-company:not(:first-of-type) {
  margin-top: 12px;
}

.product-card-terms-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.5em;

  @media (max-width: 576px) {
    font-size: 0.9em;
  }

  .product-card-terms-link {
    color: #1f92fc;
    margin-left: 0.3em;
    cursor: pointer;
    &:hover {
      color: #11487a;
    }
  }
}
</style>
